import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { listInterface } from 'interfaces';
import { slugify } from 'utils/slugify';
import { BsCollection } from 'react-icons/bs';
import { trackEvent } from 'utils/analytics';

function ListSearchComponent({
  listData,
  clickable,
  listClicked,
  isSelected,
}: {
  listData: listInterface.IList;
  clickable: boolean;
  listClicked?: Function;
  isSelected?: boolean;
}) {
  const navigate = useNavigate();

  const goToList = useCallback(async () => {
    if (listClicked) listClicked();
    return navigate(`/list/${slugify(listData.name)}/${listData.uuid}`);
  }, [listClicked, listData.name, listData.uuid, navigate]);

  const handleClick = useCallback(() => {
    if (clickable) {
      trackEvent('Collection Click from Search');
      // Small delay to ensure tracking completes
      setTimeout(() => {
        goToList();
      }, 100);
    }
  }, [clickable, goToList]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (clickable && (e.key === 'Enter' || e.key === ' ')) {
        e.preventDefault();
        if (clickable) {
          trackEvent('Collection Click from Search');
          // Small delay to ensure tracking completes
          setTimeout(() => {
            goToList();
          }, 100);
        }
      }
    },
    [clickable, goToList]
  );

  return (
    <div
      className={`appComponent ${isSelected ? 'selected' : ''}`}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      key={listData.uuid}
    >
      <div className="dataBox">
        <div className="iconBox" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
          <BsCollection size={24} color="white" />
        </div>
        <div className="textBox">
          <p className="title">{listData.name}</p>
        </div>
      </div>
    </div>
  );
}

export default ListSearchComponent;
