declare global {
  interface Window {
    umami?: {
      track: (eventName: string, data?: Record<string, any>) => void;
    };
  }
}

export const trackEvent = (eventName: string, data?: Record<string, any>) => {
  if (window.umami) {
    window.umami.track(eventName, data);
  }
};
