import React from 'react';
import classNames from 'classnames';

export function Chip({ type, children, classname, onClick }: any) {
  return (
    <button
      className={classNames('chip', classname)}
      data-umami-event={`${type === 'category' ? 'Category Click' : 'Tag Click'}`}
      data-type={type}
      onClick={() => onClick()}
    >
      {type === 'category' ? children : `#${children}`}
    </button>
  );
}
