/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback, Suspense } from 'react';
import { ISubscription, ISubscriptionTotal, IUser } from 'interfaces/userInterface';
import { subscriptionsController } from 'controllers';
import { Subscription } from 'components/subscriptions/Subscription';
import styles from './SubscriptionsPage.module.scss';
import { Helmet } from 'react-helmet';
import { BsXLg } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { CloneSubscription } from 'components/subscriptions/CloneSubscription';
import { debounce } from 'lodash';
import { handleEmailIteration } from 'helpers/emailToastHelper';

// Lazy load components for better initial page load performance
const LazySettingsPanel = React.lazy(() =>
  import('../../components/subscriptions/SettingsPanel').then((module) => ({ default: module.SettingsPanel }))
);
const LazyAddSubscriptionPanel = React.lazy(() =>
  import('../../components/subscriptions/AddSubscriptionPanel').then((module) => ({
    default: module.AddSubscriptionPanel,
  }))
);

type TabType = 'subscriptions' | 'add' | 'settings';

interface SubscriptionsPageProps {
  userData: IUser;
  reloadUser: () => void;
}

function SubscriptionsPage({ userData, reloadUser }: SubscriptionsPageProps) {
  const [searchParams] = useSearchParams();
  const emailData = useMemo(
    () => ({
      frequency: userData?.config?.subscriptions?.emailFrequency?.name,
      time: handleEmailIteration(userData?.config?.subscriptions?.emailFrequency?.sentTime),
      currency: userData?.config?.subscriptions?.preferredCurrency?.name || 'USD',
    }),
    [userData]
  );
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [showSuggestedSubscriptions, setShowSuggestedSubscriptions] = useState<boolean>(false);
  const [suggestedSubscriptions, setSuggestedSubscriptions] = useState<ISubscription[]>([]);
  const [totals, setTotals] = useState<ISubscriptionTotal[]>([]);
  const [total, setTotal] = useState<string>();
  const [activeTab, setActiveTab] = useState<TabType>('subscriptions');
  const [search, setSearch] = useState<string>(searchParams.get('subApp') || '');
  const [searchResults, setSearchResults] = useState<ISubscription[]>([]);

  // Calculate monthly cost for a subscription
  const getMonthlyAmount = useCallback((sub: ISubscription) => {
    if (!sub || typeof sub.cost !== 'number' || isNaN(sub.cost)) {
      return 0;
    }

    // Don't round intermediate calculations to maintain precision
    return sub.interval === 'year' ? sub.cost / 12 : sub.cost;
  }, []);

  // Calculate total monthly cost for a list of subscriptions
  const calculateTotal = useCallback(
    (subs: ISubscription[]) => {
      // Handle empty subscriptions array
      if (!subs || subs.length === 0) {
        const currencyName = userData.config?.subscriptions?.preferredCurrency?.name || 'USD';
        const currencySymbol = currencyName === 'USD' ? '$' : currencyName;
        return `${currencySymbol}0.00`;
      }

      // Calculate total with full precision, ensure numbers are valid
      const monthlyTotal = subs.reduce((acc, sub) => {
        const amount = getMonthlyAmount(sub);
        // Check if amount is a valid number
        return acc + (isNaN(amount) ? 0 : amount);
      }, 0);

      const currencyName = userData.config?.subscriptions?.preferredCurrency?.name || 'USD';
      const currencySymbol = currencyName === 'USD' ? '$' : currencyName;

      // Ensure the final number is valid
      const roundedTotal = isNaN(monthlyTotal) ? 0 : Math.round(monthlyTotal * 100) / 100;
      return `${currencySymbol}${roundedTotal.toFixed(2)}`;
    },
    [getMonthlyAmount, userData.config?.subscriptions?.preferredCurrency?.name]
  );

  // Move sorting logic outside of the callback
  const sortSubscriptions = useCallback(
    (subs: ISubscription[]) => {
      return [...subs].sort((a, b) => getMonthlyAmount(b) - getMonthlyAmount(a));
    },
    [getMonthlyAmount]
  );

  const getSubscriptions = useCallback(async () => {
    try {
      const [subscriptionsFetch, suggestedSubscriptionsFetch] = await Promise.all([
        subscriptionsController.getSubscriptions(),
        subscriptionsController.getSuggestedSubscriptions(),
      ]);

      if (!!subscriptionsFetch.subscriptions.length) {
        const sortedSubscriptions = sortSubscriptions(subscriptionsFetch.subscriptions);
        setSubscriptions(sortedSubscriptions);
        setSearchResults(sortedSubscriptions);
        // Calculate and set the total based on all subscriptions
        setTotal(calculateTotal(sortedSubscriptions));
      } else {
        setSubscriptions([]);
        setSearchResults([]);
        setTotal(calculateTotal([]));
      }

      setSuggestedSubscriptions(suggestedSubscriptionsFetch.subscriptions);

      if (!subscriptionsFetch.subscriptions.length && !!suggestedSubscriptionsFetch.subscriptions.length) {
        setShowSuggestedSubscriptions(true);
      }

      if (
        subscriptionsFetch.totals.length === 1 &&
        subscriptionsFetch.totals[0].currency === userData.config?.subscriptions?.preferredCurrency?.id
      ) {
        setTotals([]);
      } else {
        setTotals(subscriptionsFetch.totals);
      }
    } catch (error: any) {
      console.error('Error fetching subscriptions:', error);
      // Set defaults in case of error
      setTotal(calculateTotal([]));
      setSubscriptions([]);
      setSearchResults([]);
    }
  }, [userData, sortSubscriptions, calculateTotal]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm: string) => {
        const filteredResults = subscriptions.filter((subscription) =>
          subscription.app.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(filteredResults);
        // Update total to reflect filtered results
        setTotal(calculateTotal(filteredResults));
      }, 300),
    [subscriptions, calculateTotal]
  );

  useEffect(() => {
    debouncedSearch(search);
    return () => {
      debouncedSearch.cancel();
      // Reset total to show all subscriptions when search is cleared
      if (!search) {
        setTotal(calculateTotal(subscriptions));
      }
    };
  }, [search, debouncedSearch, calculateTotal, subscriptions]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const handleAddSubscription = useCallback(
    async (subData: any) => {
      try {
        await subscriptionsController.addSubscription({
          ...subData,
          currency: subData.currency?.value,
        });
        await getSubscriptions();
        setActiveTab('subscriptions');
      } catch (error: any) {
        console.error('Error adding subscription:', error);
      }
    },
    [getSubscriptions]
  );

  const subscriptionCardsList = useMemo(
    () => (
      <div className={styles.subscriptionCards}>
        {searchResults.map((subscription) => (
          <Subscription
            key={subscription.uuid}
            subscription={subscription}
            getSubscriptions={getSubscriptions}
            editable
          />
        ))}
      </div>
    ),
    [searchResults, getSubscriptions]
  );

  return (
    <div className={styles.subscriptionsPage}>
      <Helmet>
        <title>Subscriptions - Slocco</title>
      </Helmet>

      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.totalCard}>
            <div className={styles.totalAmount}>{total}</div>
            <div className={styles.totalDescription}>per month for {subscriptions.length} subscriptions</div>
            <div className={styles.cardDescription}>
              Track and manage all your subscriptions in one place. Get insights into your monthly spending.
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.subscriptionsContainer}>
          <div className={styles.tabsContainer} role="tablist" aria-label="Subscription tabs">
            <button
              className={`${styles.tab} ${activeTab === 'subscriptions' ? styles.active : ''}`}
              onClick={() => setActiveTab('subscriptions')}
              role="tab"
              aria-selected={activeTab === 'subscriptions'}
              aria-controls="subscriptions-panel"
              id="subscriptions-tab"
            >
              Your Subscriptions
            </button>
            <button
              className={`${styles.tab} ${activeTab === 'add' ? styles.active : ''}`}
              onClick={() => {
                setActiveTab('add');
                setSubscriptions([...subscriptions]);
              }}
              role="tab"
              aria-selected={activeTab === 'add'}
              aria-controls="add-subscription-panel"
              id="add-subscription-tab"
            >
              Track New Subscription
            </button>
            <button
              className={`${styles.tab} ${activeTab === 'settings' ? styles.active : ''}`}
              onClick={() => setActiveTab('settings')}
              role="tab"
              aria-selected={activeTab === 'settings'}
              aria-controls="settings-panel"
              id="settings-tab"
            >
              Settings
            </button>
          </div>

          <div
            role="tabpanel"
            id="subscriptions-panel"
            aria-labelledby="subscriptions-tab"
            hidden={activeTab !== 'subscriptions'}
          >
            <div className={styles.searchSubscription}>
              <input
                type="text"
                placeholder="Search your subscriptions..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search && <BsXLg className={styles.clearIcon} onClick={() => setSearch('')} />}
            </div>

            {subscriptionCardsList}

            {showSuggestedSubscriptions && (
              <div className={styles.popularSubscriptions}>
                <h2>Popular Subscriptions</h2>
                <div className={styles.subscriptionCards}>
                  {suggestedSubscriptions.map((subscription) => (
                    <CloneSubscription
                      key={subscription.uuid}
                      subscriptionToEdit={subscription}
                      saveSubscription={handleAddSubscription}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          <Suspense fallback={<div className={styles.loadingState}>Loading...</div>}>
            {activeTab === 'add' && (
              <LazyAddSubscriptionPanel
                currency={userData.config?.subscriptions?.preferredCurrency?.id || 'USD'}
                suggestedSubscriptions={suggestedSubscriptions}
                handleAddSubscription={handleAddSubscription}
                onComplete={async () => {
                  await getSubscriptions();
                  setActiveTab('subscriptions');
                  setShowSuggestedSubscriptions(false);
                }}
              />
            )}

            {activeTab === 'settings' && (
              <LazySettingsPanel
                emailData={emailData}
                reloadUser={reloadUser}
                total={total}
                totals={totals}
                userData={userData}
              />
            )}
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SubscriptionsPage);
