import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { BsXLg } from 'react-icons/bs';
import { useSnackbar } from 'notistack';
import 'styles/pages/listPage.scss';
import { appController, listController, userController } from 'controllers';
import { appInterface, listInterface, userInterface } from 'interfaces';
import { CheckboxField, InputField, LoadingSpinner } from 'components/generic';
import { ExtendedAppList } from 'components/apps';
import { SavableSuggestions } from 'components/search';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDelete } from 'react-icons/md';
import NotFoundPage from './NotFoundPage';
import { appHelper } from 'helpers';
import { IApp, IExternalParsedApp } from 'interfaces/appInterface';
import { Helmet } from 'react-helmet';

function ListPage({ userData, isLoggedIn }: { userData?: userInterface.IUser; isLoggedIn?: boolean }) {
  const params = useParams();
  const navigate = useNavigate();
  const uuid: string | undefined = params.uuid;
  const [newListData, setNewListData] = useState<listInterface.INewList>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [owned, setOwned] = useState<boolean>(false);
  const [ownerData, setOwnerData] = useState<userInterface.IUserPublic>();
  const [list, setList] = useState<listInterface.IList>();
  const [apps, setApps] = useState<appInterface.IApp[]>([]);
  const [appSearch, setAppSearch] = useState<string>('');
  const [suggestions, setSuggestions] = useState<(appInterface.IApp | appInterface.IExternalParsedApp)[] | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  async function getListById() {
    let listFetch: listInterface.IList;
    let appsFetch: appInterface.IApp[];
    try {
      if (!uuid) return navigate('/');
      listFetch = await listController.getListById(uuid);
      appsFetch = await appController.getManyAppsById(listFetch.apps);
      setApps(appsFetch);
      setList(listFetch);
      setNewListData(listFetch);
      return setIsLoading(false);
    } catch (error: any) {
      return navigate('/');
    }
  }

  async function saveApp(appData: appInterface.IApp) {
    try {
      if (list) {
        await listController.addAppToList({ uuid: list.uuid, appData });
        setApps((prevApps) => [...prevApps, appData]);
        setSuggestions((prevSuggestions) =>
          prevSuggestions ? prevSuggestions.filter((app) => app.uuid !== appData.uuid) : undefined
        );
        setAppSearch('');
        enqueueSnackbar('App added to list', { variant: 'success' });
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  async function deleteApp(appData: appInterface.IApp) {
    try {
      if (list) await listController.deleteAppFromList(list.uuid, appData.uuid);
      await getListById();
      return enqueueSnackbar(`${appData.title} has been removed from the list`, {
        variant: 'success',
      });
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  async function fetchOwner() {
    let ownerFetch: userInterface.IUserPublic;

    try {
      if (list) {
        ownerFetch = await userController.getPublicUserById({
          userId: list.userId,
        });
        setOwnerData(ownerFetch);
        setOwned(ownerFetch.uuid === userData?.uuid);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    getListById();
  }, [params.uuid]);

  useEffect(() => {
    fetchOwner();
  }, [params.uuid, list]);

  async function searchLocalApps() {
    let suggestionSearch: appInterface.IApp[];
    try {
      suggestionSearch = await appController.getManyAppsByName(appSearch);
      const filteredSearch = await appHelper.checkAddedApps(apps, suggestionSearch);
      return setSuggestions(filteredSearch);
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewListData({
      ...list,
      [e.target.name]: e.target.value,
    });
  }

  async function deleteList() {
    try {
      if (list) {
        await listController.deleteList({ uuid: list.uuid });
        enqueueSnackbar('List has been deleted', { variant: 'success' });
        navigate(-1);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  async function updateList() {
    try {
      if (list && newListData) {
        await listController.updateList({ uuid: list.uuid, newListData });
        await getListById();
        setEditMode(false);
        enqueueSnackbar('List Updated', { variant: 'success' });
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if (appSearch.length > 2) searchLocalApps();
    else setSuggestions(undefined);
  }, [appSearch]);

  if (isLoading) {
    return (
      <div className="listPage">
        <div className="loadingContainer">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (!list || !apps) return <NotFoundPage />;

  const isOwner = userData?.uuid === list.userId;

  return (
    <div className="listPage">
      <Helmet>
        <title>{list?.name} - Slocco</title>
      </Helmet>

      {isOwner && (
        <div className="iconWrap">
          <button className="actionButton" onClick={() => setEditMode(!editMode)} title="Edit list">
            <FiEdit />
            {editMode && <MdOutlineDelete onClick={() => deleteList()} title="Delete list" />}
          </button>
        </div>
      )}

      {editMode ? (
        <div className="inputWrapper">
          <InputField name="name" label="List Name" value={newListData?.name} handleChange={handleChange} autoFocus />
          <InputField
            name="description"
            label="Description"
            value={newListData?.description}
            handleChange={handleChange}
          />
          <CheckboxField
            name="isPublic"
            label="Public"
            isChecked={newListData?.isPublic}
            handleChange={() =>
              setNewListData({
                ...newListData,
                isPublic: !newListData?.isPublic,
              })
            }
          />
          <button type="button" onClick={updateList} className="cta">
            Save Changes
          </button>
        </div>
      ) : (
        <div className="textWrapper">
          <h1 className="title">{list.name}</h1>
          <span>
            Created by{' '}
            <Link className="user" to={`/users/${ownerData?.uuid}`}>
              {ownerData?.username}
            </Link>{' '}
            • {apps.length} {apps.length === 1 ? 'App' : 'Apps'}
          </span>
          {list.description && <p className="description">{list.description}</p>}
        </div>
      )}

      {isOwner && (
        <>
          <div className="searchBox">
            <div className="searchBox_container">
              <div className="searchIcon">
                <svg
                  aria-hidden="true"
                  fill="none"
                  focusable="false"
                  height="1em"
                  role="presentation"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="1em"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                className="searchField"
                placeholder="Search for apps to add..."
                value={appSearch}
                onChange={(e) => setAppSearch(e.target.value)}
                aria-label="Search apps"
              />
              {appSearch && (
                <button className="clearButton" onClick={() => setAppSearch('')} title="Clear search">
                  <BsXLg />
                </button>
              )}
            </div>
          </div>
          <SavableSuggestions inList apps={suggestions} saveApp={saveApp} />
        </>
      )}

      {!isLoading && (
        <ExtendedAppList apps={apps} deleteApp={isOwner ? deleteApp : undefined} isLoggedIn={isLoggedIn} />
      )}
    </div>
  );
}

export default ListPage;
