import classNames from 'classnames';
import React from 'react';
import 'styles/components/generic/inputField.scss';

export interface InputFieldProps {
  name: string;
  label?: string;
  type?: string;
  required?: boolean;
  value?: string | number;
  maxLength?: number;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  error?: string;
  autoFocus?: boolean;
  inputMode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  pattern?: string;
}

function InputField({
  name,
  label,
  required,
  type,
  value,
  handleChange,
  onKeyPress,
  disabled,
  maxLength,
  className,
  placeholder,
  error,
  autoFocus,
  inputMode,
  pattern,
}: InputFieldProps) {
  return (
    <div className={classNames('inputField', className, { error: !!error })}>
      {label && (
        <label htmlFor={name} className="label">
          {label}
        </label>
      )}
      <input
        autoComplete="off"
        disabled={disabled}
        id={name}
        name={name}
        required={required}
        type={type || 'text'}
        className="input"
        maxLength={maxLength}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        value={value}
        autoFocus={autoFocus}
        inputMode={inputMode}
        pattern={pattern}
      />
      {error && <span className="errorText">{error}</span>}
    </div>
  );
}

export default InputField;
