import React, { useState } from 'react';
import { ISubscription, IUpdateSubscription } from 'interfaces/userInterface';
import styles from './Subscription.module.scss';
import { IconButton, Tooltip } from '@mui/material';
import { subscriptionsController } from 'controllers';
import { useSnackbar } from 'notistack';
import { FiEdit2, FiPlus } from 'react-icons/fi';
import { EditSubscription } from '../EditSubscription';
import { Link } from 'react-router-dom';
import { slugify } from 'utils/slugify';
import Modal from 'components/generic/modal';

interface SubscriptionProps {
  subscription: ISubscription;
  getSubscriptions?: () => void;
  editable?: boolean;
  addSubscription?: (subscription: ISubscription) => void;
}

export const Subscription = ({ subscription, getSubscriptions, editable, addSubscription }: SubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showEditModal, setShowEditModal] = useState(false);

  const deleteSubscription = async () => {
    try {
      await subscriptionsController.deleteSubscription({ subscriptionId: subscription.uuid });
      if (getSubscriptions) await getSubscriptions();
      setShowEditModal(false);
      enqueueSnackbar('Subscription deleted', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const saveSubscription = async (subscriptionData: IUpdateSubscription) => {
    try {
      await subscriptionsController.editSubscription({
        ...subscriptionData,
        currency: subscriptionData.currency?.value,
      });
      if (getSubscriptions) await getSubscriptions();
      setShowEditModal(false);
      enqueueSnackbar('Subscription updated', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <>
      <div className={styles.subscription}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <Link to={`/app/${slugify(subscription.app.title)}/${subscription.app.uuid}`} className={styles.appLink}>
              <img src={subscription.app?.imageUrl || ''} className={styles.appIcon} alt="App Icon" />
              <span>{subscription.app?.title}</span>
            </Link>
          </div>
          <div className={styles.headerActions}>
            <span className={styles.price}>
              {subscription.cost} {subscription.currency} / {subscription.interval}
            </span>
            {addSubscription && (
              <Tooltip title="Add Subscription">
                <IconButton onClick={() => addSubscription(subscription)} className={styles.actionButton}>
                  <FiPlus />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>

        {subscription.note && (
          <>
            <div className={styles.divider} />
            <div className={styles.noteSection}>
              <span className={styles.noteLabel}>NOTES:</span>
              <p className={styles.note}>{subscription.note?.replace(/\s+/g, ' ')}</p>
            </div>
          </>
        )}

        <div className={styles.divider} />
        <div className={styles.footer}>
          {editable && (
            <button
              className={styles.actionButton}
              onClick={() => setShowEditModal(true)}
              aria-label="Edit subscription"
            >
              <FiEdit2 />
            </button>
          )}
        </div>
      </div>

      {showEditModal && (
        <Modal hideModal={() => setShowEditModal(false)} title="Edit Subscription">
          <EditSubscription
            subscriptionToEdit={subscription}
            onCancel={() => setShowEditModal(false)}
            onSave={saveSubscription}
            onDelete={deleteSubscription}
          />
        </Modal>
      )}
    </>
  );
};
