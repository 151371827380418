import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { ListTable } from 'components/list';
import { listController, userController } from 'controllers';
import { listInterface, pageInterface, userInterface } from 'interfaces';
import { IUser } from 'interfaces/userInterface';
import 'styles/pages/feedPage.scss';
import { Link } from 'react-router-dom';
import { UserPreview } from 'components/users';
import { pageSize } from 'utils/constants';
import { Helmet } from 'react-helmet';
import GlassCardContainer from '../components/generic/GlassCard';

interface FeedPageProps {
  userData?: IUser;
  reloadUser?: Function;
}

function FeedPage({ userData, reloadUser }: FeedPageProps) {
  const [isLoading, setLoading] = useState(true);
  const [listData, setListData] = useState<listInterface.INewsFeedList[]>();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [followedUsers, setFollowedUsers] = useState<userInterface.IUserPublic[]>();
  const [followedLists] = useState<listInterface.IList[]>();
  const { enqueueSnackbar } = useSnackbar();

  const canLoadMore = total > page * pageSize;

  async function getLists() {
    let listSearch: pageInterface.IPage<listInterface.INewsFeedList>;
    try {
      listSearch = await listController.getNewsFeed({
        page: page + 1,
      });
      setTotal(listSearch.total);
      if (listData) setListData([...listData, ...listSearch.items]);
      else setListData(listSearch.items);
      setPage(page + 1);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
    setLoading(false);
  }

  async function findUsers() {
    try {
      let usersFetch;
      const followedUserIds = userData?.followedUsers?.map((followedUser) => followedUser.id);
      if (followedUserIds && followedUserIds?.length)
        usersFetch = await userController.getProtectedUsersByIds({
          uuidList: followedUserIds,
        });
      return setFollowedUsers(usersFetch);
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    findUsers();
  }, [userData]);

  useEffect(() => {
    getLists();
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <Helmet>
        <title>Slocco: Your App Discovery Platform</title>
      </Helmet>
      <div className="feedPage">
        <section className="hero">
          <div className="heroContent">
            <h1>
              Discover and manage your <span>digital ecosystem</span>
            </h1>
            <p>
              Slocco helps you find, organize, and track the applications that power your workflow. Join to share and
              discover the best tools.
            </p>
          </div>
        </section>

        <div className="content">
          <GlassCardContainer
            maxCards={3}
            cards={[
              {
                heading: 'App Management',
                description: 'Discover apps, discounts alerts, and any other red flags all in one place.',
                sectionImage: '/images/section.jpeg',
                learnMoreLink: '/login',
              },
              {
                heading: 'Smart Collections',
                description:
                  'Organize and track your favorite applications in one place. Create collections, share with others, and discover new tools for your workflow.',
                sectionImage: '/images/section.jpeg',
                learnMoreLink: '/login',
              },
              {
                heading: 'Track Subscriptions',
                description:
                  'Track all the apps you have subscribed to, in one place. Find good alternatives, discounts, codes and save money',
                sectionImage: '/images/section.jpeg',
                learnMoreLink: '/login',
              },
            ]}
          />

          {followedUsers && followedUsers.length > 0 && (
            <section className="section">
              <h2>Your Network</h2>
              <div className="followedUsers">
                {followedUsers.map((user) => (
                  <div key={user.uuid} className="userWrapper">
                    <Link to={`/users/${user.uuid}`}>
                      <UserPreview userData={user} />
                    </Link>
                  </div>
                ))}
              </div>
            </section>
          )}

          <section className="section">
            <h2>Featured Collections</h2>
            {listData && listData.length > 0 ? (
              <>
                <ListTable
                  allowFollowing={!!userData}
                  userData={userData}
                  vertical
                  reloadUser={reloadUser ? () => reloadUser() : undefined}
                  reloadLists={() => getLists()}
                  newsFeed={listData.filter((list) => !!list.apps.length)}
                />
                {canLoadMore && (
                  <div style={{ textAlign: 'center' }}>
                    <button className="loadMore" onClick={getLists}>
                      Show more
                    </button>
                  </div>
                )}
              </>
            ) : (
              <p>Start by following some users to discover their curated collections!</p>
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default FeedPage;
