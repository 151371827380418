import React, { useState, KeyboardEvent } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import 'styles/pages/onboarding/credentials.scss';
import { InputField, PasswordInputField } from 'components/generic';
import { userController } from 'controllers';
import { userInterface } from 'interfaces';
import { errorHelper } from 'helpers';
import { Helmet } from 'react-helmet';

function LoginPage({ setIsLoggedIn }: { setIsLoggedIn: React.Dispatch<React.SetStateAction<any>> }) {
  const [loginData, setLoginData] = useState<userInterface.IUserLogin>({
    username: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ username?: string; password?: string }>({});
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state }: any = useLocation();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
    // Clear error when user starts typing
    if (errors[name as keyof typeof errors]) {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
  }

  function validateForm(): boolean {
    const newErrors: { username?: string; password?: string } = {};

    if (!loginData.username) {
      newErrors.username = 'Email/Username is required';
    }
    if (!loginData.password) {
      newErrors.password = 'Password is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  async function handleLogin() {
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      if (loginData) {
        const response = await userController.login(loginData);
        if (response.waitlist) {
          enqueueSnackbar('You are on the waitlist for now, we will let you know once you will be able to login.', {
            variant: 'info',
          });
          return navigate('/');
        }
        enqueueSnackbar('Successfully logged in', { variant: 'success' });
        setIsLoggedIn(true);
        state?.redirect ? navigate(-1) : navigate('/');
      } else {
        throw new errorHelper.CodeError('Login data is missing', 401);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="loginPage">
      <Helmet>
        <title>Login - Slocco</title>
      </Helmet>

      <div className="login-container">
        <div className="form-container">
          <h1 className="title">Hello again!</h1>
          <p className="subtitle">Please enter your details to sign in</p>

          <div className="input-wrapper">
            <InputField
              name="username"
              label="Email/Username"
              value={loginData?.username}
              handleChange={handleChange}
              onKeyPress={handleKeyPress}
              error={errors.username}
              autoFocus
            />
            <PasswordInputField
              name="password"
              label="Password"
              value={loginData?.password}
              handleChange={handleChange}
              onKeyPress={handleKeyPress}
              error={errors.password}
            />
          </div>

          <div className="actions">
            <button
              type="button"
              onClick={handleLogin}
              className={`btn-login ${isLoading ? 'loading' : ''}`}
              disabled={isLoading}
              data-umami-event="Sign In button"
            >
              {isLoading ? (
                <span className="loading-text">
                  <span className="loader"></span>
                  Signing in...
                </span>
              ) : (
                'Sign In'
              )}
            </button>

            <Link to="/reset">Forgot your password?</Link>
          </div>

          <div className="footer">
            <p className="register">
              Don&apos;t have an account? <Link to="/register">Create an account</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
