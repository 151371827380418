import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import 'styles/components/apps/extendedAppList.scss';
import { appInterface } from 'interfaces';
import { Platform } from 'interfaces/appInterface';
import { platformOptionsKeys } from 'utils/constants';
import { slugify } from 'utils/slugify';
import { IoClose } from 'react-icons/io5';
import { MdOutlineModeEdit } from 'react-icons/md';
import { Button } from '@mui/material';

function ExtendedAppList({ apps, deleteApp, isLoggedIn }: { apps: appInterface.IApp[]; deleteApp?: Function; isLoggedIn?: boolean }) {
  const [localApps, setLocalApps] = useState<appInterface.IApp[]>(apps);
  const [expandDescription, setExpandDescription] = useState<boolean[]>(apps.map(() => false));
  const [isEditing, setIsEditing] = useState<number>();

  useEffect(() => {
    setLocalApps(apps);
    setExpandDescription(apps.map(() => false));
  }, [apps]);

  const handleExpandDescription = (i: number) => {
    const holder = [...expandDescription];
    holder[i] = !holder[i];
    return setExpandDescription(holder);
  };

  const handleDelete = async (app: appInterface.IApp, index: number) => {
    if (deleteApp) {
      await deleteApp(app);
      const updatedApps = localApps.filter((_, i) => i !== index);
      setLocalApps(updatedApps);
      setExpandDescription(updatedApps.map(() => false));
      setIsEditing(-1);
    }
  };

  return (
    <div className="extendedAppList">
      {localApps &&
        localApps.length > 0 &&
        localApps.map((app, i: number) => (
          <div key={app.uuid} className="appComponent">
            <div className="appHeader">
              <div className="appTitleSection">
                <Link to={`/app/${slugify(app.title)}/${app.uuid}`}>
                  <img referrerPolicy="no-referrer" className="appImage" src={app.imageUrl} alt={app.title} />
                </Link>
                <div className="appInfo">
                  <Link className="appUrl" to={`/app/${slugify(app.title)}/${app.uuid}`}>
                    <p className="title">{app.title}</p>
                  </Link>
                  {app.sellerName && <p className="statusBadge">By: {app.sellerName}</p>}
                </div>
              </div>
              {isLoggedIn && (
                <div className="appComponent_edit">
                  {isEditing === i ? (
                    <div className="edit_actions">
                      <div className="appComponent_edit_menu_list">
                        <Button onClick={() => handleDelete(app, i)}>Remove</Button>
                      </div>
                      <IoClose onClick={() => setIsEditing(-1)} className="cancel_icon" />
                    </div>
                  ) : (
                    <MdOutlineModeEdit onClick={() => setIsEditing(i)} className="edit_icon_in_my_list" />
                  )}
                </div>
              )}
            </div>
            <div className="textBox">
              <div className="categoriesPlatformsTags">
                <div className="pugBox">
                  {!!app?.platforms &&
                    Object.keys(app?.platforms)
                      .filter((p) => p !== 'requests' && !!app?.platforms[p as Platform]?.url)
                      .map((p) => (
                        <a
                          key={p}
                          className="linkBox platforms"
                          rel="noreferrer"
                          target="_blank"
                          href={app.platforms[p as Platform]?.url ?? '/'}
                        >
                          {platformOptionsKeys[p as Platform]}
                        </a>
                      ))}
                </div>
                <div className="pugBox">
                  {!!app?.categories?.length &&
                    app?.categories.map((c, idx) => (
                      <Link key={`${c}-${idx}`} className="linkBox" to={`/apps?category=${c}`}>
                        {c}
                      </Link>
                    ))}
                  {!!app?.tags?.length &&
                    app?.tags.map((t, idx) => (
                      <Link key={`${t}-${idx}`} className="linkBox tags" to={`/apps?tag=${t}`}>
                        {t}
                      </Link>
                    ))}
                </div>
              </div>
              <p
                className={classNames('description', {
                  expanded: expandDescription[i],
                })}
              >
                {expandDescription[i]
                  ? app.description
                  : `${app.description.slice(0, 100)}${app.description.length > 100 ? '...' : ''}`}
                {app.description.length > 100 && (
                  <span className="show-more-link" onClick={() => handleExpandDescription(i)}>
                    {expandDescription[i] ? ' Show Less' : ' Show More'}
                  </span>
                )}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ExtendedAppList;
