import React, { useState, useEffect, useCallback, useMemo } from 'react';
import 'styles/components/search/suggestions.scss';
import { appInterface, listInterface } from 'interfaces';
import { AppSearchComponent } from '.';
import ListSearchComponent from './listSearchComponent';

interface SuggestionsProps {
  apps?: appInterface.IApp[];
  lists?: listInterface.IList[];
  appClicked?: Function;
  listClicked?: Function;
  selectedIndex?: number;
  isLoading?: boolean;
}

function Suggestions({
  apps = [],
  lists = [],
  appClicked,
  listClicked,
  selectedIndex = -1,
  isLoading = false,
}: SuggestionsProps) {
  const [localSelectedIndex, setLocalSelectedIndex] = useState<number>(selectedIndex);

  useEffect(() => {
    setLocalSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  const allItems = useMemo(() => [...lists, ...apps], [lists, apps]);

  useEffect(() => {
    // Reset selection when search results change
    setLocalSelectedIndex(-1);
  }, [apps, lists]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!allItems.length) return;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setLocalSelectedIndex((prev) => (prev < allItems.length - 1 ? prev + 1 : prev));
          break;
        case 'ArrowUp':
          e.preventDefault();
          setLocalSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
          break;
        case 'Enter':
          e.preventDefault();
          if (localSelectedIndex >= 0) {
            const selectedItem = allItems[localSelectedIndex];
            if ('title' in selectedItem) {
              appClicked?.();
            } else {
              listClicked?.();
            }
          }
          break;
        default:
          break;
      }
    },
    [allItems, localSelectedIndex, appClicked, listClicked]
  );

  const renderContent = () => {
    if (isLoading) {
      return <div className="loading">Searching...</div>;
    }

    if (!allItems.length) {
      return <div className="no-results">No results found</div>;
    }

    return (
      <>
        {lists.map((list, index) => (
          <ListSearchComponent
            key={list.uuid}
            listData={list}
            clickable={true}
            listClicked={listClicked}
            isSelected={localSelectedIndex === index}
          />
        ))}
        {apps.map((app, index) => (
          <AppSearchComponent
            key={app.uuid}
            appData={app}
            clickable={true}
            appClicked={appClicked}
            isSelected={localSelectedIndex === index + lists.length}
          />
        ))}
      </>
    );
  };

  return (
    <div
      className={`suggestions ${allItems.length > 0 || isLoading ? 'active' : ''}`}
      onKeyDown={handleKeyDown}
      tabIndex={-1}
      role="listbox"
      aria-busy={isLoading}
    >
      {renderContent()}
    </div>
  );
}

export default React.memo(Suggestions);
