import React, { useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { appInterface } from 'interfaces';
import { slugify } from 'utils/slugify';
import { trackEvent } from 'utils/analytics';

function AppSearchComponent({
  appData,
  clickable,
  appClicked,
  isSelected,
}: {
  appData: appInterface.IExternalParsedApp | appInterface.IApp;
  clickable: boolean;
  appClicked?: Function;
  isSelected?: boolean;
}) {
  const navigate = useNavigate();

  const goToApp = useCallback(async () => {
    if (appClicked) appClicked();
    return navigate(`/app/${slugify(appData.title)}/${appData.uuid}`);
  }, [appClicked, appData.title, appData.uuid, navigate]);

  const handleClick = useCallback(() => {
    if (clickable) {
      trackEvent('App Click from Search');
      // Small delay to ensure tracking completes
      setTimeout(() => {
        goToApp();
      }, 100);
    }
  }, [clickable, goToApp]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (clickable && (e.key === 'Enter' || e.key === ' ')) {
        e.preventDefault();
        handleClick();
      }
    },
    [clickable, handleClick]
  );

  return (
    <div
      className={`appComponent ${isSelected ? 'selected' : ''}`}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      key={appData.uuid}
    >
      <div className="dataBox">
        <img
          className="appImage"
          height="35"
          width="35"
          loading="lazy"
          decoding="async"
          referrerPolicy="no-referrer"
          src={appData.imageUrl}
          alt={appData.title}
        />
        <div className="textBox">
          <p className="title">{appData.title}</p>
        </div>
      </div>
    </div>
  );
}

export default memo(AppSearchComponent);
