import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import 'styles/pages/onboarding/resetPassEmailPage.scss';
import { InputField } from 'components/generic';
import { userController } from 'controllers';
import { errorHelper } from 'helpers';

function ResetPassEmailPage() {
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  async function requestPassReset() {
    try {
      if (email) await userController.requestPassReset({ email });
      else throw new errorHelper.CodeError('Please enter your email', 401);
      enqueueSnackbar('We sent you an email with the code', {
        variant: 'success',
      });
      return navigate('/reset/verify', { state: { email } });
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  return (
    <div className="resetPassEmailPage">
      <h1 className="title">Reset Password</h1>
      <div className="inputWrapper">
        <InputField
          required
          name="email"
          label="Email"
          type="email"
          value={email}
          handleChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button type="button" onClick={requestPassReset} className="cta" data-umami-event="Request Password Reset">
        Request Password Reset
      </button>
    </div>
  );
}

export default ResetPassEmailPage;
