import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { ISubscription, IUpdateSubscription, SubscriptionPlan } from 'interfaces/userInterface';
import { InputField, SelectorField } from 'components/generic';
import { LabelValue } from 'interfaces/generic';
import { AppList } from 'components/apps';
import styles from './EditSubscription.module.scss';
import { currencyOptions } from 'utils/constants';

interface EditSubscriptionProps {
  subscriptionToEdit: ISubscription;
  onCancel: () => void;
  onDelete: () => void;
  onSave: (subscription: IUpdateSubscription) => void;
}

const billingIntervals: LabelValue[] = [
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
];

export const EditSubscription = ({ subscriptionToEdit, onDelete, onSave, onCancel }: EditSubscriptionProps) => {
  const [subscriptionData, setSubscriptionData] = useState<IUpdateSubscription>({
    ...subscriptionToEdit,
    appId: subscriptionToEdit?.app?.uuid,
    currency: { value: subscriptionToEdit.currency || 'USD', label: subscriptionToEdit.currency || 'USD' },
  });

  const [costInput, setCostInput] = useState(subscriptionData.cost.toString());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setSubscriptionData((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  const handleIntervalChange = (e: LabelValue) => {
    setSubscriptionData((s) => ({
      ...s,
      interval: e.value as 'month' | 'year',
    }));
  };

  const handleCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === '' || value === '.') {
      setCostInput(value);
      setSubscriptionData((prev) => ({
        ...prev,
        cost: 0,
      }));
      return;
    }

    if (!/^\d*\.?\d{0,2}$/.test(value)) {
      return;
    }

    setCostInput(value);
    setSubscriptionData((prev) => ({
      ...prev,
      cost: parseFloat(value),
    }));
  };

  return (
    <div className={styles.editSubscription}>
      <AppList noNavigate apps={[subscriptionToEdit.app]} />
      {subscriptionData.plan === SubscriptionPlan.Paid && (
        <>
          <div className={styles.priceBox}>
            <InputField name="cost" label="Cost" value={costInput} handleChange={handleCostChange} />
            <SelectorField
              name="currency"
              label="Currency"
              options={currencyOptions}
              value={subscriptionData.currency}
              handleChange={(e: LabelValue) => setSubscriptionData((s) => ({ ...s, currency: e }))}
            />
            <SelectorField
              name="interval"
              label="Billing Interval"
              options={billingIntervals}
              value={billingIntervals.find((i) => i.value === subscriptionData.interval)}
              handleChange={handleIntervalChange}
            />
            <div className={styles.noteField}>
              <label>Notes</label>
              <TextField
                name="note"
                multiline
                rows={4}
                value={subscriptionData.note || ''}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                InputProps={{
                  className: styles.noteInput,
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className={styles.buttonGroup}>
        <Button onClick={onDelete} color="secondary">
          Delete
        </Button>
        <div className={styles.rightButtons}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onSave(subscriptionData)} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
