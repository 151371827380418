import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import 'styles/components/generic/menu.scss';
import { Suggestions } from 'components/search';
import { BsXLg, BsPerson } from 'react-icons/bs';
import { InnerMenuProps } from './menu';
import Logo from 'assets/icons/Logo';
import { Button } from './Button';
import { slugify } from 'utils/slugify';

export const DesktopMenu = (props: InnerMenuProps) => {
  const { logout, isLoggedIn, getAllApps, count, lists, apps } = props;
  const { search, setSearch, showSearch, setShowSearch } = props;
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const allItems = [...(lists || []), ...(apps || [])];

  const searchFieldRef = useRef<HTMLInputElement>(null);

  // Handle global keyboard shortcuts
  useEffect(() => {
    const handleGlobalKeyDown = (event: KeyboardEvent) => {
      // Only handle '/' shortcut if we're not in an input field
      if (event.key === '/' && document.activeElement?.tagName !== 'INPUT') {
        event.preventDefault();
        searchFieldRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, []);

  // Reset selection when search results change
  useEffect(() => {
    setSelectedIndex(-1);
  }, [apps, lists]);

  const clearSearchAndFocus = () => {
    setSearch('');
    setShowSearch(false);
    searchFieldRef.current?.blur();
    setSelectedIndex(-1);
  };

  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!showSearch) return;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (allItems.length > 0) {
          setSelectedIndex((prev) => (prev < allItems.length - 1 ? prev + 1 : prev));
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (allItems.length > 0) {
          setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (selectedIndex >= 0 && allItems.length > 0) {
          const selectedItem = allItems[selectedIndex];
          if ('title' in selectedItem) {
            // It's an app
            navigate(`/app/${slugify(selectedItem.title)}/${selectedItem.uuid}`);
            clearSearchAndFocus();
          } else {
            // It's a list
            navigate(`/list/${slugify(selectedItem.name)}/${selectedItem.uuid}`);
            clearSearchAndFocus();
          }
        }
        break;
      case 'Escape':
        event.preventDefault();
        clearSearchAndFocus();
        break;
      default:
        break;
    }
  };

  return (
    <div className="menu">
      <div className="logoBox">
        <Link className="logo" to="/">
          <Logo />
        </Link>
      </div>

      {isLoggedIn && (
        <div className="menuBox">
          <Link className="menuText" to="/new-app">
            Add New App
          </Link>
          <Link className="menuText" to="/lists/owned">
            My Lists
          </Link>
          <Link className="menuText" to="/lists/followed">
            Favourite Lists
          </Link>
          <Link className="menuText" to="/subscriptions">
            My Subscriptions
          </Link>
        </div>
      )}
      <div
        className="searchBox"
        onBlur={(e) => {
          if (e.relatedTarget === null) {
            setShowSearch(false);
            setSelectedIndex(-1);
          }
        }}
        onFocus={() => setShowSearch(true)}
      >
        {['/login', '/register'].indexOf(window.location.pathname) === -1 ? (
          <div className="searchWrap">
            <div className="searchContainer">
              <input
                ref={searchFieldRef}
                className="searchField"
                value={search}
                placeholder="Search Apps and Collections"
                onChange={(v) => setSearch(v.target.value)}
                onKeyDown={handleSearchKeyDown}
              />
              {search.length > 0 && (
                <BsXLg
                  className="clearIcon"
                  onClick={() => {
                    clearSearchAndFocus();
                  }}
                />
              )}

              {search.length > 2 && (!!apps.length || !!lists.length) && showSearch && (
                <Suggestions
                  appClicked={clearSearchAndFocus}
                  listClicked={clearSearchAndFocus}
                  lists={lists}
                  apps={apps}
                  selectedIndex={selectedIndex}
                />
              )}
              {search === '' && <span className="shortcutKey">/</span>}
            </div>

            {isLoggedIn ? (
              <>
                <BsPerson onClick={() => navigate('/profile')} className="widget" />
                <FiLogOut onClick={() => logout()} className="widget" data-umami-event="Logout Menubar" />
              </>
            ) : (
              <Button onClick={() => navigate('/login')} className="loginButton" umamiEvent="Login Menubar">
                Login
              </Button>
            )}
          </div>
        ) : null}
        {search.length === 0 && apps.length < count && showSearch && (
          <button type="button" onClick={() => getAllApps()} className="cta">
            Load More
          </button>
        )}
      </div>
    </div>
  );
};
