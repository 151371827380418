import React from 'react';
import styles from './GlassCard.module.scss';
import classNames from 'classnames';

interface CardProps {
  heading: string;
  description: string;
  learnMoreLink?: string;
  sectionImage?: string;
}

const Card: React.FC<CardProps> = ({ heading, description, learnMoreLink, sectionImage }) => (
  <div className={styles.glassCard}>
    <div className={styles.cardContent}>
      <div className={styles.content}>
        <h3 className={styles.heading}>{heading}</h3>
        <p className={styles.description}>{description}</p>
        {learnMoreLink && (
          <a href={learnMoreLink} className={styles.learnMore}>
            Learn more
          </a>
        )}
      </div>
    </div>
    {sectionImage && (
      <div className={styles.sectionImageWrapper}>
        <img src={sectionImage} alt="" className={styles.sectionImage} />
      </div>
    )}
  </div>
);

interface GlassCardContainerProps {
  cards: CardProps[];
  maxCards?: 2 | 3;
}

const GlassCardContainer: React.FC<GlassCardContainerProps> = ({ cards, maxCards = 2 }) => {
  // Limit the number of cards based on maxCards prop
  const displayCards = cards.slice(0, maxCards);

  return (
    <div
      className={classNames(styles.glassCardContainer, {
        [styles.threeCards]: maxCards === 3,
      })}
    >
      {displayCards.map((card, index) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};

export default GlassCardContainer;
