import React, { useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { BsXLg } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDelete } from 'react-icons/md';
import { BiExpandAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import 'styles/components/generic/modal.scss';

function Modal({
  children,
  hideModal,
  editModal,
  deleteModal,
  owned,
  expandLink,
  title,
}: {
  children: JSX.Element | JSX.Element[];
  hideModal: Function;
  editModal?: Function;
  deleteModal?: Function;
  owned?: boolean;
  expandLink?: string;
  title?: string;
}) {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        hideModal();
      }
    },
    [hideModal]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  }, [handleKeyDown]);

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      hideModal();
    }
  };

  const modalContent = (
    <div className="modal" onClick={handleBackdropClick}>
      <div className="modal-content">
        <div className="modal-header">
          {title && <h2>{title}</h2>}
          <div className="modal-actions">
            {owned && editModal && (
              <button onClick={() => editModal()} aria-label="Edit">
                <FiEdit />
              </button>
            )}
            {owned && deleteModal && (
              <button onClick={() => deleteModal()} aria-label="Delete">
                <MdOutlineDelete />
              </button>
            )}
            {expandLink && (
              <Link to={expandLink}>
                <button aria-label="Expand">
                  <BiExpandAlt />
                </button>
              </Link>
            )}
            <button onClick={() => hideModal()} aria-label="Close">
              <BsXLg />
            </button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
}

export default Modal;
