import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import {
  AppPage,
  NotFoundPage,
  NewAppPage,
  ProfilePage,
  ListPage,
  PublicListsPage,
  SearchPage,
  MyListsPage,
  ProfileSettingsPage,
  FeedPage,
  UserPage,
  AllAppsPage,
  SubscriptionsPage,
} from 'pages';
import {
  LoginPage,
  // RegisterPage,
  VerifyEmailPage,
  ResetPassEmailPage,
  VerifyPassPage,
  NewPasswordPage,
} from 'pages/onboarding';
import { userController } from 'controllers';
import { Menu } from 'components/generic';
import 'styles/App.scss';
import { IJwtDecode, IUser } from 'interfaces/userInterface';
import { TermsConditions } from 'pages/TermsConditions';
import JoinPage from 'pages/onboarding/JoinPage';
import { Helmet } from 'react-helmet';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import AuthVerify from 'utils/authVerify/authVerify';

function App() {
  const localUserData = localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData') as string)
    : undefined;
  const [userData, setUserData] = useState<IUser | undefined>(localUserData);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(localStorage.getItem('isLoggedIn') === 'true');
  const navigate = useNavigate();
  const location = useLocation();

  const executeLogin = (state: boolean) => {
    setIsLoggedIn(state);
    localStorage.setItem('isLoggedIn', JSON.stringify(state));
    if (!state) {
      localStorage.removeItem('userData');
      setUserData(undefined);
    }
  };

  const refreshUser = async () => {
    try {
      const userFetch = await userController.getUser();
      const emailVerified = userFetch?.config?.onboarding?.emailVerified;
      if (!userFetch && !['/login', '/register', '/'].some((cur) => location.pathname === cur)) navigate('/login');
      else if (userFetch && !emailVerified && location.pathname !== '/verify-email') navigate('/verify-email');
      else if (userFetch && emailVerified && location.pathname === '/login') navigate('/');
      if (!userFetch && isLoggedIn) executeLogin(false);
      else executeLogin(true);
      setUserData(userFetch);
      localStorage.setItem('userData', JSON.stringify(userFetch));
    } catch {
      executeLogin(false);
    }
  };

  const verifyToken = () => {
    const token = Cookies.get('accessToken');
    if (token) {
      const status: IJwtDecode = jwtDecode(token);
      if (status.exp * 1000 < Date.now()) {
        executeLogin(false);
        return navigate('/login', { state: { redirect: true } });
      }
    }
  };

  useEffect(() => {
    refreshUser();
  }, [isLoggedIn]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState !== 'hidden') {
        verifyToken();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Slocco</title>
      </Helmet>
      <header>
        <Menu isLoggedIn={isLoggedIn} setIsLoggedIn={executeLogin} />
      </header>
      <Routes>
        <Route path="/" element={<FeedPage reloadUser={refreshUser} userData={userData} />} />
        <Route path="/app/:name/:uuid" element={<AppPage isLoggedIn={isLoggedIn} userData={userData} />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/list/:name/:uuid" element={<ListPage userData={userData} isLoggedIn={isLoggedIn} />} />
        <Route path="/login" element={<LoginPage setIsLoggedIn={executeLogin} />} />
        {/* <Route
          path="/register"
          element={<RegisterPage setIsLoggedIn={executeLogin} />}
        /> */}
        <Route path="/register" element={<JoinPage setIsLoggedIn={executeLogin} />} />
        <Route path="/reset" element={<ResetPassEmailPage />} />
        <Route path="/reset/verify" element={<VerifyPassPage />} />
        <Route path="/reset/new-password" element={<NewPasswordPage />} />
        <Route path="/users/:uuid" element={<UserPage isLoggedIn={isLoggedIn} />} />
        <Route path="/verify-email" element={<VerifyEmailPage setIsLoggedIn={executeLogin} />} />
        <Route path="/apps" element={<AllAppsPage />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        {isLoggedIn && userData && (
          <>
            <Route path="/new-app" element={<NewAppPage isLoggedIn={isLoggedIn} />} />
            <Route path="/subscriptions" element={<SubscriptionsPage userData={userData} reloadUser={refreshUser} />} />
            <Route
              path="/lists/followed"
              element={<PublicListsPage followed userData={userData} reloadUser={refreshUser} />}
            />
            <Route path="/profile" element={<ProfilePage userData={userData} />} />
            <Route path="/profile/lists" element={<MyListsPage userData={userData} />} />
            <Route path="/profile/settings" element={<ProfileSettingsPage userData={userData} />} />
            <Route path="/lists/owned" element={<MyListsPage userData={userData} />} />
          </>
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {isLoggedIn && userData && <AuthVerify setIsLoggedIn={executeLogin} />}
    </div>
  );
}

export default App;
