/* eslint-disable max-len */
import React from 'react';

export function Logo({ width = 150, height = 26 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 150 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <text
        x="50%"
        y="72%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="white"
        fontFamily="Arial"
        fontSize="24"
        fontWeight="700"
      >
        slocco
      </text>
    </svg>
  );
}
