import React from 'react';
import { Link } from 'react-router-dom';
import 'styles/components/apps/appList.scss';
import { BsXLg } from 'react-icons/bs';
import { appInterface } from 'interfaces';
import { slugify } from 'utils/slugify';

interface AppListProps {
  apps?: appInterface.IApp[];
  description?: boolean;
  deleteApp?: Function;
  owned?: boolean;
  noNavigate?: boolean;
  title?: string;
}

function AppList({ apps, deleteApp, description, owned, noNavigate, title }: AppListProps) {
  return (
    <div className="appList">
      {apps &&
        apps.length > 0 &&
        apps
          .filter((app) => !!app)
          .map((app) => (
            <div
              key={app.uuid}
              className="appComponent"
              style={
                title ? { border: '1px solid rgba(168, 85, 247, 0.2)', borderRadius: '4px', padding: '0.8rem' } : {}
              }
            >
              <Link className="appUrlImage" to={noNavigate ? '' : `/app/${slugify(app.title)}/${app.uuid}`}>
                <img className="appImage" referrerPolicy="no-referrer" src={app.imageUrl} alt={app.title} />
              </Link>
              <div className="textBox">
                <Link className="appUrl" to={noNavigate ? '' : `/app/${slugify(app.title)}/${app.uuid}`}>
                  <p className="title">{app.title}</p>
                </Link>
                {description && <p className="description">{app.description}</p>}
              </div>
              {/* {app.status && <span className="statusBadge">{app.status}</span>} */}
              {owned && deleteApp && (
                <BsXLg
                  onClick={() => {
                    deleteApp(app);
                  }}
                  className="appDeleteIcon"
                />
              )}
            </div>
          ))}
    </div>
  );
}

export default AppList;
