import React, { useState } from 'react';
import { Button, ButtonGroup, TextField, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ISubscription, IUpdateSubscription, SubscriptionPlan } from 'interfaces/userInterface';
import { InputField, SelectorField } from 'components/generic';
import { LabelValue } from 'interfaces/generic';
import { AppList } from 'components/apps';
import styles from './CloneSubscription.module.scss';
import { currencyOptions } from 'utils/constants';
import { slugify } from 'utils/slugify';

interface AddSubscriptionProps {
  subscriptionToEdit: ISubscription;
  saveSubscription: (subscription: IUpdateSubscription) => void;
  userCurrency?: string;
}

const billingIntervals = {
  month: { value: 'month', label: 'Monthly' },
  year: { value: 'year', label: 'Yearly' },
};

export const CloneSubscription = ({ subscriptionToEdit, saveSubscription, userCurrency }: AddSubscriptionProps) => {
  const [subscriptionData, setSubscriptionData] = useState<IUpdateSubscription>({
    ...subscriptionToEdit,
    appId: subscriptionToEdit?.app?.uuid,
    currency: {
      value: userCurrency || subscriptionToEdit.currency || 'USD',
      label: userCurrency || subscriptionToEdit.currency || 'USD',
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setSubscriptionData((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  const handleSaveSubscription = () => {
    saveSubscription(subscriptionData);
  };

  const onCancel = () => {
    // Add cancel logic here
  };

  return (
    <div className={styles.editSubscription}>
      <div className={styles.content}>
        <div className={styles.appHeader}>
          <Link to={`/app/${slugify(subscriptionToEdit?.app?.title)}/${subscriptionToEdit?.app?.uuid}`} className={styles.appLink}>
            <div className={styles.appIcon}>
              <img 
                src={subscriptionToEdit?.app?.imageUrl || ''} 
                alt="App Icon"
                className={styles.icon}
              />
            </div>
            <div className={styles.appName}>
              {subscriptionToEdit?.app?.title}
            </div>
          </Link>
        </div>
        {subscriptionData.plan === SubscriptionPlan.Paid && (
          <div>
            <div className={styles.field}>
              <label>Amount</label>
              <InputField
                name="cost"
                placeholder="0"
                value={subscriptionData.cost?.toString() || ''}
                handleChange={handleChange}
              />
            </div>

            <div className={styles.field}>
              <label>Currency</label>
              <SelectorField
                name="currency"
                options={currencyOptions}
                value={subscriptionData.currency}
                handleChange={(e: LabelValue) => setSubscriptionData((s) => ({ ...s, currency: e }))}
              />
            </div>

            <div className={styles.field}>
              <label>Billing Frequency</label>
              <SelectorField
                name="billing-frequency"
                options={Object.values(billingIntervals)}
                value={billingIntervals[subscriptionData.interval]}
                handleChange={(e: LabelValue) =>
                  setSubscriptionData({ ...subscriptionData, interval: e.value as 'month' | 'year' })
                }
              />
            </div>
          </div>
        )}

        <div className={styles.field}>
          <label>Notes</label>
          <InputField
            name="note"
            placeholder="Notes"
            value={subscriptionData.note}
            handleChange={(e) => setSubscriptionData((s) => ({ ...s, note: e.target.value }))}
          />
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={onCancel} className={styles.cancel}>
          Cancel
        </button>
        <button onClick={handleSaveSubscription} className={styles.addButton}>
          Add Subscription
        </button>
      </div>
    </div>
  );
};
