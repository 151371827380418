/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import 'styles/components/apps/appPlatforms.scss';
import { BsXLg } from 'react-icons/bs';
import { appInterface } from 'interfaces';
import { NewPlatformPopup } from 'components/apps';
import { platformOptionsKeys } from 'utils/constants';
import { Platform, PlatformSelector } from 'interfaces/appInterface';
import Add from 'assets/icons/Add';
import { Button } from 'components/generic/Button';
import DynamicIcon from 'components/SvgRender/SvgRender';

interface AppPlatformsProps {
  label?: string;
  platforms?: appInterface.IPlatforms;
  appName?: string;
  addNewPlatform: (platform: appInterface.INewPlatform) => void;
  removePlatform?: Function;
  onAdd?: (
    platform?: appInterface.INewPlatform,
    app?: appInterface.IExternalParsedApp,
    country?: { value: string; label: string }
  ) => void;
  isLoggedIn?: boolean;
}

function AppPlatforms({
  label,
  platforms,
  appName,
  addNewPlatform,
  removePlatform,
  onAdd,
  isLoggedIn,
}: AppPlatformsProps) {
  const [showModal, setShowModal] = useState(false);
  const [showPlatformSuggest, setShowPlatformSuggest] = useState(false);
  const [preSelectedPlatform, setPreSelectedPlatform] = useState<PlatformSelector>();

  useEffect(() => {
    if (!!platforms?.itunes && !platforms?.android) {
      setShowPlatformSuggest(true);
      setPreSelectedPlatform({
        value: Platform.android,
        label: platformOptionsKeys.android,
      });
    } else if (!!platforms?.android && !platforms?.itunes) {
      setShowPlatformSuggest(true);
      setPreSelectedPlatform({
        value: Platform.itunes,
        label: platformOptionsKeys.itunes,
      });
    } else setShowPlatformSuggest(false);
  }, [platforms?.android, platforms?.itunes]);

  let platformKeys: appInterface.Platform[] | 'requests'[] = [];
  if (platforms) platformKeys = Object.keys(platforms) as appInterface.Platform[] | 'requests'[];
  return (
    <div className="platforms">
      {isLoggedIn && showModal && (
        <NewPlatformPopup
          preSelectedPlatform={preSelectedPlatform}
          hideModal={() => setShowModal(false)}
          appName={appName}
          addNewPlatform={(platform: appInterface.INewPlatform) => addNewPlatform(platform)}
          onAdd={onAdd}
        />
      )}
      <div className="appPlatforms">
        {label && <h3>{label}</h3>}
        <div className="appPlatformsWrapper">
          <span className="download-links">DOWNLOAD LINKS</span>
          <div className="platform-buttons">
            {!!platformKeys.length &&
              platforms &&
              platformKeys.map(
                (platformKey: appInterface.Platform | 'requests' | '_id') =>
                  platformKey !== 'requests' &&
                  platformKey !== '_id' &&
                  platforms[platformKey]?.url && (
                    <div className="appPlatformBoxWrapper" key={platformKey}>
                      <a href={platforms[platformKey]?.url} target="_blank" rel="noreferrer">
                        <div className="appPlatformBox">
                          <Button
                            umamiEvent={platformOptionsKeys[platformKey]}
                            icon={<DynamicIcon height={18} width={18} name={platformOptionsKeys[platformKey]} />}
                          >
                            <span>{platformOptionsKeys[platformKey]}</span>
                          </Button>
                        </div>
                      </a>
                      {removePlatform && (
                        <BsXLg
                          onClick={() => {
                            removePlatform(platformKey);
                          }}
                          className="platformDeleteIcon"
                        />
                      )}
                    </div>
                  )
              )}
            {isLoggedIn && platformKeys.map((key: string) => key !== 'requests' && key !== '_id').length <= 6 && (
              <>
                <Button type="plain" onClick={() => setShowModal(true)}>
                  <Add size={30} />
                </Button>
                {showPlatformSuggest && (
                  <span>Consider adding another mobile platform by clicking on the plus icon above</span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppPlatforms;
